import React from 'react'
import PropTypes from 'prop-types'
import Layout from 'components/Layout'
import SEO from 'components/Seo'
import { IntlProvider } from 'contexts/intl/IntlContext'
import Contact from '../../components/Contact'

const Index = ({ location }) => (
  <IntlProvider pathname={location.pathname}>
    {({ locale, formatMessage, getPath }) => (
      <Layout>
        <SEO
          lang={locale}
          title={formatMessage({ id: 'contact.title' })}
          description={formatMessage({ id: 'meta.description' })}
          keywords={formatMessage({ id: 'meta.keywords' })}
          url={formatMessage({ id: 'meta.url.contact' })}
          isBlogPost={false}
          ogTitle={formatMessage({ id: 'contact.title' })}
          ogUrl={formatMessage({ id: 'meta.url.contact' })}
          ogDescription={formatMessage({ id: 'meta.description' })}
          ogKeywords={formatMessage({ id: 'meta.keywords' })}
          ogLocale={locale}
          twitterTitle={formatMessage({ id: 'contact.title' })}
          twitterDescription={formatMessage({ id: 'meta.description' })}
          twitterCreator={'Mihir Pipermitwala'}
          siteName={'Mihir Pipermitwala'}
        />
        <Contact></Contact>
      </Layout>
    )}
  </IntlProvider>
)

Index.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default Index
