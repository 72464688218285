import React, { Component } from 'react'
import { navigate } from 'gatsby-link'
import { FaEnvelope, FaUser, FaMobile } from 'react-icons/fa'
import { FormattedMessage } from 'react-intl'
import Sentry from 'gatsby-plugin-sentry'
import PageTitle from '../PageTitle'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'contact-form': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => {
        Sentry.captureException(error)
        alert(error)
      })
  }

  render() {
    return (
      <>
        <section className='section is-size-5-desktop is-size-6-touch'>
          <PageTitle pageTitle={<FormattedMessage id='contact.title' />} />
          <div className='container'>
            <hr />
            <div className='content'>
              <h5 className='subtitle has-text-centered has-text-light is-5'>Get in touch</h5>
              <form
                name='ContactUsForm'
                method='post'
                action='/contact/thanks/'
                data-netlify='true'
                data-netlify-honeypot='bot-field'
                onSubmit={this.handleSubmit}
              >
                <input type='hidden' name='contact-form' value='ContactUsForm' />
                <div hidden>
                  <label>
                    Don’t fill this out: <input name='bot-field' onChange={this.handleChange} />
                  </label>
                </div>
                <div className='field is-horizontal'>
                  <div className='field-label is-normal'>
                    <label className='label has-text-white' htmlFor='name' required>
                      Name
                    </label>
                  </div>
                  <div className='field-body'>
                    <div className='field'>
                      <div className='control has-icons-left'>
                        <input
                          className='input'
                          type='text'
                          name='name'
                          onChange={this.handleChange}
                          id='name'
                          required={true}
                          placeholder={'e.g Mihir Pipermitwala'}
                        />
                        <span className='icon is-left'>
                          <FaUser />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='field is-horizontal'>
                  <div className='field-label is-normal'>
                    <label className='label has-text-white' htmlFor='email' required>
                      Email
                    </label>
                  </div>
                  <div className='field-body'>
                    <div className='field'>
                      <div className='control is-expanded has-icons-left'>
                        <input
                          className='input'
                          type='email'
                          name='email'
                          onChange={this.handleChange}
                          id='email'
                          required={true}
                          placeholder={'e.g. mihirpipermitwala@gmail.com'}
                        />
                        <span className='icon is-left'>
                          <FaEnvelope />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='field is-horizontal'>
                  <div className='field-label is-normal'>
                    <label className='label has-text-white' htmlFor='mobile' required>
                      Mobile
                    </label>
                  </div>
                  <div className='field-body'>
                    <div className='field'>
                      <div className='control has-icons-left'>
                        <input
                          className='input'
                          type='mobile'
                          name='mobile'
                          onChange={this.handleChange}
                          id='mobile'
                          required={true}
                          placeholder={'e.g 123-456-7890'}
                        />
                        <span className='icon is-left'>
                          <FaMobile />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='field is-horizontal'>
                  <div className='field-label is-normal'>
                    <label className='label has-text-white' htmlFor='subject' required>
                      Subject
                    </label>
                  </div>
                  <div className='field-body'>
                    <div className='field'>
                      <div className='control'>
                        <textarea
                          className='input'
                          type='text'
                          name='subject'
                          onChange={this.handleChange}
                          id='subject'
                          required={true}
                          placeholder={'e.g Subject'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='field is-horizontal'>
                  <div className='field-label is-normal'>
                    <label className='label has-text-white' htmlFor='message' required>
                      Question
                    </label>
                  </div>
                  <div className='field-body'>
                    <div className='field'>
                      <div className='control'>
                        <textarea
                          className='textarea'
                          name='message'
                          onChange={this.handleChange}
                          id='message'
                          required={true}
                          placeholder={'e.g Message'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='field is-grouped is-grouped-right'>
                  <p className='control'>
                    <button className='button has-background-light is-light' type='submit'>
                      Submit
                    </button>
                  </p>
                  <p className='control'>
                    <button className='button has-background-dark' type='reset'>
                      Cancel
                    </button>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </section>
      </>
    )
  }
}
